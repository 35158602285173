let isScroll = document.querySelector('body')
let menuOpen = document.querySelector('.js-burger')
let menu = document.querySelector('.js-menu')
menuOpen & menuOpen.addEventListener('click', () => {
  isScroll.classList.toggle('no-scroll')
  menu.classList.toggle('is-active')
})



